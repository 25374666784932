<template>
    <div>
  
      <div class="card">
        <div class="card-body">
          <button type="button" style="float: right"  data-bs-toggle="modal" data-bs-target="#createChecklistModal" class="btn btn-primary px-5">New Checklist</button>
          <pagination @search="loadChecklists" :data="pagination"></pagination>
  
          <table id="example" class="table table-hover" style="width:100%">
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="checklist in checklists" :key="checklist.id" style="cursor: pointer;" @click="$router.push({name: 'admin.checklists.view', params: {id: checklist.id}})">
                <td>{{checklist.name}}</td>
                <td>{{checklist.description}}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
            </tfoot>
          </table>
  
          <pagination @search="loadChecklists" :data="pagination"></pagination>
        </div>
      </div>
  
      <!-- MODALS -->
  
      <div class="modal fade" id="createChecklistModal" tabindex="-1" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Create Checklist</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12 mb-2">
                  <p>Fields marked <span class="text-danger">*</span> are required</p>
                </div>
                <div class="col-md-12 mb-2">
                  <label for="checklistName" class="form-label">Name <span class="text-danger">*</span></label>
                  <input type="text" class="form-control" id="checklistName" v-model="checklist.name">
                </div>
                <div class="col-md-12 mb-2">
                    <label for="checklistDescription" class="form-label">Description <span class="text-danger">*</span></label>
                    <textarea class="form-control" id="checklistDescription" v-model="checklist.description"></textarea>
                  </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='clostCreateChecklistModal'>Close</button>
              <submit-button type="button" :background="'btn btn-primary'" :clicked="creating" @click="createChecklist" text="Save changes"></submit-button>
            </div>
          </div>
        </div>
      </div>
  
    </div>
  </template>
  
  <script>
  /* global $ */
  import axios from 'axios';
  import Pagination from '../../../components/Pagination.vue';
  import SubmitButton from '../../../components/SubmitButton.vue';
  export default {
    components: { Pagination, SubmitButton },
    name: 'admin.checklists',
    data(){
      return {
        checklists: [],
        pagination: {
          lastPage: 0,
          page: 0,
          showing: 0,
          total: 0
        },
        checklist: {
          name: '',
          description: ''
        },
        creating: false
      }
    },
    mounted(){
      this.loadChecklists()
    },
    methods: {
      loadChecklists(page = 1){
        axios.get(`${process.env.VUE_APP_API_URL}/v1/checklist?page=${page}`)
        .then(response => {
          this.checklists = response.data.checklists;
          this.pagination = response.data.pagination;
        })
        .catch(error => {
          this.$error('Failed to load checklists.', error);
        });
      },
      createChecklist(){
        this.creating = true;
        axios.post(`${process.env.VUE_APP_API_URL}/v1/checklist`, {
            name: this.checklist.name,
            description: this.checklist.description
        })
        .then(() => {
          $('#closeCreateChecklistModal').click();
          this.$success("Created new checklist.");
          this.creating = false;
          this.checklist = {
            name: '',
            description: ''
          }
          this.loadChecklists(this.pagination.page);
        })
        .catch(error => {
          this.$error("Failed to create new checklist.", error);
          this.creating = false;
        })
      }
  
    }
  }
  </script>